<template>
	<div
		v-intro="
			'<h2>Progresse et atteint le statut de maître</h2>Obtiens les différents statuts en remplissant la jauge avec les points gagnés.'
		"
		v-intro-tooltip-class="'custom-tooltip-introjs'"
	>
		<h2>
			Progression
			<v-tooltip color="black" right>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<v-icon size="20px" color="grey lighten-1">mdi-information-outline</v-icon>
					</v-btn>
				</template>
				<span>Points gagnés : {{ globalCurrentScore }}/12000</span>
			</v-tooltip>
		</h2>
		<v-col offset-md="0" md="11" cols="11">
			<div class="mx-auto">
				<ProgressionBarChart :parcours="parcours" />
			</div>
		</v-col>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProgressionBarChart from '@/components/charts/ProgressionBarChart';
import { calTotalRulePoints } from '../../../utils/score';

export default {
	name: 'ProgressionBarForDashboard',
	components: { ProgressionBarChart },
	data() {
		return {
			parcours: null,
		};
	},
	created() {
		this.getParcoursInFormationProgress(this.$route.params.idParcours);
	},
	watch: {
		formationProgress() {
			this.getParcoursInFormationProgress(this.$route.params.idParcours);
		},
		'$route.params.idParcours'(newId, oldId) {
			if (newId === oldId) return;
			else this.getParcoursInFormationProgress(newId);
		},
	},
	computed: {
		...mapGetters('profile', {
			formationProgress: 'formationProgress',
		}),
		globalCurrentScore() {
			if (this.parcours == null) return '';
			return calTotalRulePoints(this.parcours);
		},
	},
	methods: {
		getParcoursInFormationProgress(idParcours) {
			if (this.formationProgress == null || idParcours == null) return null;
			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === idParcours
			);
			if (currentParcoursItem == null) return;
			this.parcours = currentParcoursItem.parcours_id;
		},
	},
};
</script>

<style lang="scss" scoped></style>
