<template>
	<div>
		<v-text-field
			outlined
			clearable
			label="Rechercher..."
			prepend-inner-icon="mdi-magnify"
			v-model="searchText"
			@input="searchRules"
			color="black"
			hide-details
		></v-text-field>
		<v-row>
			<v-col cols="6" class="pb-0 pt-0 mb10">
				<v-select
					:items="familyTags || []"
					item-text="name"
					placeholder="Toutes les familles"
					@change="selectFamily($event)"
					color="black"
				>
				</v-select>
			</v-col>
			<v-col cols="6" class="pb-0 pt-0">
				<v-select
					:items="status"
					placeholder="Tous les statuts"
					@change="selectStatus($event)"
					color="black"
				></v-select>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'SearchAndFilter',
	computed: {
		...mapGetters('rulesDashboard', {
			familyTags: 'familyTags',
		}),
	},
	data() {
		return {
			status: ['Tous les statuts', 'En cours', 'Maîtrisées', 'Mémorisées'],
			searchText: '',
		};
	},
	async mounted() {
		try {
			await this.$store.dispatch('rulesDashboard/getFamilyTags');
		} catch (error) {
			console.log(error);
		}
		if (this.familyTags) this.familyTags.unshift({ name: 'Toutes les familles' });
	},
	methods: {
		selectFamily(value) {
			this.$emit('familySelected', value);
		},
		selectStatus(value) {
			this.$emit('statusSelected', value);
		},
		searchRules() {
			this.$emit('searchRules', this.searchText);
		},
	},
};
</script>
