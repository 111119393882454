var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{directives:[{name:"intro-autostart",rawName:"v-intro-autostart",value:(_vm.autoStartTourRules),expression:"autoStartTourRules"},{name:"intro-autostart",rawName:"v-intro-autostart.config",value:(_vm.autostartRulesConfig),expression:"autostartRulesConfig",modifiers:{"config":true}},{name:"intro-autostart",rawName:"v-intro-autostart:on.complete",value:(_vm.onFinishTourRules),expression:"onFinishTourRules",arg:"on",modifiers:{"complete":true}},{name:"intro-autostart",rawName:"v-intro-autostart:on.exit",value:(_vm.onFinishTourRules),expression:"onFinishTourRules",arg:"on",modifiers:{"exit":true}}],staticClass:"mt-1"},[_c('v-col',{staticClass:"blockRules hide-scroll-bar",attrs:{"cols":"12","md":"3"}},[_c('SearchAndFilter',{directives:[{name:"intro",rawName:"v-intro",value:('<h2>Recherche les règles que tu veux revoir</h2>Utilise les filtres ou la barre de recherche'),expression:"'<h2>Recherche les règles que tu veux revoir</h2>Utilise les filtres ou la barre de recherche'"},{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"}],attrs:{"id":"search"},on:{"familySelected":_vm.familySelected,"statusSelected":_vm.statusSelected,"searchRules":_vm.searchRulesFind}}),_c('CardsObjectivesRules',{directives:[{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"},{name:"intro",rawName:"v-intro",value:(
					'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'
				),expression:"\n\t\t\t\t\t'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'\n\t\t\t\t"}],staticClass:"CardsObjectivesRules-mobile",attrs:{"totalRuleOfFormation":_vm.totalRulesOfFormation,"nbRulesValidated":_vm.nbRulesValidated,"nbRulesMemorized":_vm.nbRulesMemorized,"nbreRulesView":_vm.nbreRulesView,"id":"objective-rule"}}),_vm._l((_vm.rulesDisplay),function(rule,index){return _c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
					el: '#rule-' + rule.id,
					duration: 1000,
				}),expression:"{\n\t\t\t\t\tel: '#rule-' + rule.id,\n\t\t\t\t\tduration: 1000,\n\t\t\t\t}"},{name:"intro",rawName:"v-intro",value:(
					'<h2>Clique sur une règle pour la revoir</h2>Retrouve la description de la règle ainsi que les sessions associées.'
				),expression:"\n\t\t\t\t\t'<h2>Clique sur une règle pour la revoir</h2>Retrouve la description de la règle ainsi que les sessions associées.'\n\t\t\t\t"},{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"},{name:"intro-if",rawName:"v-intro-if",value:(index == 0),expression:"index == 0"}],key:index,staticClass:"mb-2 cardRules",attrs:{"id":index == 0 ? 'rule' : false},on:{"click":function($event){return _vm.displayRulesInfos(rule)}}},[_c('RulesCards',{attrs:{"rule":rule,"ruleSelected":_vm.ruleSelected}})],1)})],2),_c('v-col',{attrs:{"id":"rulesInfo hide-scroll-bar","cols":"12","md":"9"}},[_c('CardsObjectivesRules',{directives:[{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"},{name:"intro",rawName:"v-intro",value:(
					'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'
				),expression:"\n\t\t\t\t\t'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'\n\t\t\t\t"}],staticClass:"CardsObjectivesRules-desktop",attrs:{"totalRuleOfFormation":_vm.totalRulesOfFormation,"nbRulesValidated":_vm.nbRulesValidated,"nbRulesMemorized":_vm.nbRulesMemorized,"nbreRulesView":_vm.nbreRulesView,"id":"objective-rule"}}),(_vm.displayRules)?_c('div',[_c('RulesInfos',{attrs:{"infosRules":_vm.infosRules,"idFormation":_vm.$route.params.idFormation},on:{"displayRuleFromSession":_vm.displayRuleFromSession}})],1):_c('div',{staticClass:"d-flex justify-center align-center flex-column mt-10",staticStyle:{"height":"60%"}},[_c('img',{staticStyle:{"width":"20rem"},attrs:{"src":require("@/assets/images/direction.png"),"alt":"arrow direction","id":"imgDirection"}}),_c('p',{staticClass:"font-weight-bold rulesInfo-instruction-desktop"},[_vm._v("← Clique sur une règle pour revoir l'astuce")])])],1)],1),_c('ModalForRestrictedUser',{attrs:{"testTrialData":_vm.formationInfos.test_trial,"modalName":"reglesPage"}}),_c('Loading',{attrs:{"show":_vm.loading_show}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }