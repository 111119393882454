<template>
	<div>
		<DashboardComponent></DashboardComponent>
	</div>
</template>

<script>
import DashboardComponent from '../components/dashboard/Index';

export default {
	name: 'Dashboard',
	components: {
		DashboardComponent,
	},
};
</script>
