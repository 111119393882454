<template>
	<div>
		<v-dialog v-model="dialog" class="modal-choice-cat">
			<v-card>
				<v-row class="modal-choice-cat-row mr-0 ml-0">
					<v-col class="pt-10 pb-10 d-flex flex-column text-center">
						<div class="pr-10 pl-10">
							<h1 class="mb-3">J-{{ decompteDays }}</h1>
							<p class="font-weight-black mb-6">Pour continuer choisis ton prochain programme</p>
						</div>
						<div>
							<v-btn
								class="hvr-grow actionButton mb-2"
								dark
								:to="{
									name: 'Report Category',
									params: { idFormation: formationId, idCategory: lastCategoryIdDone },
									query: { step: 2 },
								}"
							>
								Choisir mon programme
							</v-btn>
							<p class="mb-0 ortho-text-gray backToDashboard" @click="dialog = false">Accéder à mon espace perso</p>
						</div>
					</v-col>
					<v-col class="col-nb-cats-done d-flex flex-column justify-center align-center text-center pa-6">
						<h3>Les programmes terminés</h3>
						<h1>{{ listCategoriesDone.length }}</h1>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'AlertChoiceCategory',
	props: {
		listCategoriesDone: Array,
		listCategories: Array,
		formationId: String,
	},

	data() {
		return {
			dialog: true,
			decompteDays: 0,
		};
	},

	mounted() {
		const today = moment().startOf('day');
		const inThreeDays = moment(this.listCategoriesDone[0].done_date).startOf('day').add(3, 'days');

		this.decompteDays = inThreeDays.diff(today, 'day');
	},
	computed: {
		lastCategoryIdDone() {
			const cloneArr = [...this.listCategoriesDone];
			if (cloneArr.length === 0) return;
			cloneArr.sort((a, b) => new Date(b.done_date) - new Date(a.done_date));
			return cloneArr[0].category_id._id;
		},
	},
};
</script>

<style lang="scss" scoped>
.backToDashboard {
	cursor: pointer;
}
h1 {
	font-size: 50px;
}
.col-nb-cats-done {
	background: #8685e5 !important;
	color: white;
}
.v-card__text,
.v-card__title {
	word-break: normal;
}
::v-deep {
	.v-dialog {
		width: 50%;
		overflow-y: hidden !important;
	}

	@media screen and (max-width: 375px) {
		.actionButton {
			font-size: 11px;
		}
	}
	@media screen and (max-width: 600px) {
		.v-dialog {
			width: 90%;
		}
		.col-nb-cats-done {
			display: none !important;
		}
	}
}
</style>
