<template>
	<!-- <div>
		<WeeklyLeaderBoard v-if="formationProgress" :formationProgress="formationProgress" :profileInfos="profileInfos" />
	</div> -->
	<div class="weekly-leaderboard-modal" :class="animationClassName">
		<v-container>
			<div class="header text-right">
				<v-btn text icon class="close-btn" @click="closeModalInParent">
					<v-icon x-large color="black">mdi-close</v-icon>
				</v-btn>
			</div>

			<div class="weekly-leaderboard-modal-content">
				<WeeklyLeaderBoard
					v-if="formationProgress"
					:formationProgress="formationProgress"
					:profileInfos="profileInfos"
				/>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import WeeklyLeaderBoard from '@/components/weekly-leaderboard/WeeklyLeaderBoard';
export default {
	name: 'MobileDialogContent',
	components: { WeeklyLeaderBoard },
	computed: {
		...mapGetters('profile', {
			profileInfos: 'profileInfos',
			formationProgress: 'formationProgress',
		}),
	},
	props: {
		closeModal: Function,
	},
	data() {
		return {
			animationClassName: '',
			timeOutObject: null,
		};
	},
	created() {
		this.timeOutObject = setTimeout(() => ((this.animationClassName = 'slide_up'), 100)); // Set animation class
	},
	beforeDestroy() {
		if (this.timeOutObject) clearTimeout(this.timeOutObject);
	},
	methods: {
		closeModalInParent() {
			this.animationClassName = 'slide_down';

			if (this.timeOutObject) clearTimeout(this.timeOutObject);
			this.timeOutObject = setTimeout(() => this.closeModal(), 800);
		},
	},
};
</script>

<style lang="scss" scoped>
.weekly-leaderboard-modal {
	background-color: white;
	border-top-left-radius: 1.2rem;
	border-top-right-radius: 1.2rem;
	transition: all 0.8s;
	transform: translateY(100%);
	width: 100vw;

	.header {
		height: 36px;
	}

	.weekly-leaderboard-modal-content {
		overflow-y: auto;
		scrollbar-width: none; /* Hide scrollbar in Firefox */
		-ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
	}
	.weekly-leaderboard-modal-content::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: 959px) {
		height: 80vh;
		.weekly-leaderboard-modal-content {
			height: calc(80vh - 36px - 15px); // Minus heigh of header and 15px padding of MyPositionRow
		}
	}
	@media screen and (max-width: 375px) {
		height: 85vh;
		.weekly-leaderboard-modal-content {
			height: calc(85vh - 36px - 15px); // Minus heigh of header and 15px padding of MyPositionRow
		}
	}
}

.slide_up {
	transform: translateY(0);
}
.slide_down {
	transform: translateY(100%);
}
</style>
