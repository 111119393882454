var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.isRendering)?_c('DotLoadingPage'):_c('v-row',{directives:[{name:"intro-autostart",rawName:"v-intro-autostart",value:(_vm.autoStartTourProfile),expression:"autoStartTourProfile"},{name:"intro-autostart",rawName:"v-intro-autostart.config",value:(_vm.autostartConfig),expression:"autostartConfig",modifiers:{"config":true}},{name:"intro-autostart",rawName:"v-intro-autostart:on.complete",value:(_vm.onFinishTourProfile),expression:"onFinishTourProfile",arg:"on",modifiers:{"complete":true}},{name:"intro-autostart",rawName:"v-intro-autostart:on.exit",value:(_vm.onFinishTourProfile),expression:"onFinishTourProfile",arg:"on",modifiers:{"exit":true}}],staticClass:"mt-1 fullprofilecontainer ml-0 mr-0",class:{
				palr5: _vm.$vuetify.breakpoint.smAndDown,
				palr10: _vm.$vuetify.breakpoint.mdAndUp,
			}},[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('OpenProfileModal',{attrs:{"profileData":_vm.profileData,"formationData":_vm.formationData}}),_c('h2',[_vm._v(_vm._s(_vm.profileData.first_name)+" "+_vm._s(_vm.profileData.last_name))]),_c('div',{staticClass:"strike-profile-stats mt-2 mb-2"},[(_vm.profileData.entreprise_id.slug == 'demo')?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"#686bdf","text-color":"white"}},[_c('v-avatar',{staticClass:"white strike",attrs:{"left":""}},[_vm._v(" 32 ")]),_c('span',{staticClass:"strike-punchline"},[_vm._v("Jours d'affilée")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-star")])],1):_c('v-chip',{staticClass:"mr-1",attrs:{"color":"#686bdf","text-color":"white"}},[_c('v-avatar',{staticClass:"white strike",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.strike)+" ")]),_c('span',{staticClass:"strike-punchline"},[_vm._v("Jour"),(_vm.strike > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" d'affilée")]),(_vm.strike >= 1)?_c('v-icon',{attrs:{"right":""}},[_vm._v("🔥")]):_vm._e()],1)],1)],1)]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{staticClass:"text-center pr-0 pl-0",class:{ 'd-none': _vm.$vuetify.breakpoint.mdAndUp, 'd-none': _vm.currentRouteName != 'Category Detail' },attrs:{"md":"12","cols":"12"}},[_c('TodayExercice',{attrs:{"formationData":_vm.formationData,"exercicesDisplay":_vm.exercicesDisplay}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown && !_vm.hideLeaderboardBlock)?_c('v-col',{staticClass:"pr-0 pl-0 pt-0 mb20",class:{ 'd-none': _vm.currentRouteName != 'Category Detail' },attrs:{"cols":"12"}},[_c('LeaderBoardBlock',{staticClass:"px-3 py-3 shadow-blue-not-openned ortho-card-border-radius-4"})],1):_vm._e(),(this.formationProgress)?_c('div',{attrs:{"id":"ParcoursBlock"}},[(_vm.$vuetify.breakpoint.mdAndUp == false)?_c('v-col',{directives:[{name:"intro",rawName:"v-intro",value:(
							'<h2>Tes programmes</h2>OrthographIQ c\'est 8 programmes pour perfectionner tes écrits. </br></br>Tu les verras apparaître au fur et à mesure de ton avancée.'
						),expression:"\n\t\t\t\t\t\t\t'<h2>Tes programmes</h2>OrthographIQ c\\'est 8 programmes pour perfectionner tes écrits. </br></br>Tu les verras apparaître au fur et à mesure de ton avancée.'\n\t\t\t\t\t\t"},{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"}],staticClass:"shadow-blue-not-openned mb20",class:{ 'd-none': _vm.currentRouteName === 'Category Detail' },attrs:{"id":"ParcoursBlockMobile","cols":"12"}},[_c('ListParcours',{attrs:{"formationProgress":_vm.formationProgress}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown == false)?_c('v-col',{directives:[{name:"intro",rawName:"v-intro",value:(
							'<h2>Tes programmes</h2>OrthographIQ c\'est 8 programmes pour perfectionner tes écrits. </br></br>Tu les verras apparaître au fur et à mesure de ton avancée.'
						),expression:"\n\t\t\t\t\t\t\t'<h2>Tes programmes</h2>OrthographIQ c\\'est 8 programmes pour perfectionner tes écrits. </br></br>Tu les verras apparaître au fur et à mesure de ton avancée.'\n\t\t\t\t\t\t"},{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"}],staticClass:"shadow-blue-not-openned mb20",staticStyle:{"border-radius":"5px !important"},attrs:{"id":"ParcoursBlockDesktop","cols":"12"}},[_c('ListParcours',{attrs:{"formationProgress":_vm.formationProgress}})],1):_vm._e()],1):_c('ListParcoursSkeleton'),(
						_vm.profileData.entreprise_id &&
						_vm.profileData.entreprise_id._id == '5f6c994eac759a00173cef92' &&
						_vm.checkIfEndDateApprochingTenDaysEnd() == true
					)?_c('v-alert',{staticStyle:{"display":"block"},attrs:{"border":"left","type":"info","color":"#8186e8"}},[_c('v-chip',{staticClass:"mb-2",attrs:{"color":"#686bdf","text-color":"white"}},[_c('div',{staticClass:"ortho-button-exercice-container"},[_c('img',{staticClass:"speakerEmoji",attrs:{"src":require("@/assets/icons/chrono.png"),"alt":"chrono"}}),_c('span',{staticClass:"strike-punchline"},[_vm._v("À court de temps ?")])])]),_c('h3',{staticClass:"mb-2"},[_vm._v("Gagne 1 mois de formation en plus !")]),_c('a',{staticClass:"fs12 ortho-bold white--text",attrs:{"href":"https://orthographiq.webflow.io/parrainez-vos-proches-pour-orthographiq?utm_source=app&utm_medium=dashboard-side-section&utm_campaign=parrainage","target":"_blank"},on:{"click":function($event){return _vm.goToReferralPage()}}},[_vm._v("PARRAINER MES PROCHES"),_c('v-icon',[_vm._v("mdi-menu-right")])],1)],1):_vm._e(),_c('v-col',{staticClass:"profileManagementContainer",class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12"}},[_c('ProfileManagement',{attrs:{"profileData":_vm.profileData}})],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[(this.$router.currentRoute.name != 'Leaderboard Weekly')?_c('ProgressionBarForDashboard',{class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown }}):_vm._e(),_c('router-view')],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[(_vm.exercicesDisplay.length >= 0)?_c('div',[_c('TodayExercice',{class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown },attrs:{"formationData":_vm.formationData,"exercicesDisplay":_vm.exercicesDisplay}})],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && !_vm.hideLeaderboardBlock)?_c('v-col',{staticClass:"shadow-blue-not-openned ortho-card-border-radius-4 mb20",attrs:{"cols":"12"}},[_c('LeaderBoardBlock')],1):_vm._e(),(
						_vm.$vuetify.breakpoint.smAndDown &&
						_vm.formationData &&
						_vm.formationData.last_exercice_date &&
						_vm.rulesUser &&
						_vm.rulesUser.length > 0
					)?_c('RulesProfile',{directives:[{name:"intro",rawName:"v-intro",value:(
						'<h2>Collectionne les règles et maîtrise-les !</h2>Les points gagnés lors des sessions quotidiennes te permettent de compléter les jauges des règles pour les maîtriser.'
					),expression:"\n\t\t\t\t\t\t'<h2>Collectionne les règles et maîtrise-les !</h2>Les points gagnés lors des sessions quotidiennes te permettent de compléter les jauges des règles pour les maîtriser.'\n\t\t\t\t\t"},{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"}],class:{ 'd-none': _vm.currentRouteName != 'Category Detail' },attrs:{"rulesUser":_vm.rulesUser,"formationData":_vm.formationData}}):_vm._e(),(
						_vm.$vuetify.breakpoint.mdAndDown == false &&
						_vm.formationData &&
						_vm.formationData.last_exercice_date &&
						_vm.rulesUser &&
						_vm.rulesUser.length > 0
					)?_c('RulesProfile',{directives:[{name:"intro",rawName:"v-intro",value:(
						'<h2>Collectionne les règles et maîtrise-les !</h2>Les points gagnés lors des sessions quotidiennes te permettent de compléter les jauges des règles pour les maîtriser.'
					),expression:"\n\t\t\t\t\t\t'<h2>Collectionne les règles et maîtrise-les !</h2>Les points gagnés lors des sessions quotidiennes te permettent de compléter les jauges des règles pour les maîtriser.'\n\t\t\t\t\t"},{name:"intro-tooltip-class",rawName:"v-intro-tooltip-class",value:('custom-tooltip-introjs'),expression:"'custom-tooltip-introjs'"}],attrs:{"rulesUser":_vm.rulesUser,"formationData":_vm.formationData}}):_vm._e(),_c('v-col',{class:{ 'd-none': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12"}},[_c('ProfileManagement',{attrs:{"profileData":_vm.profileData}})],1)],1)],1),(
				!_vm.isSwitchFormationStateLoading &&
				!_vm.formationData.last_exercice &&
				!_vm.formationData.last_category &&
				_vm.formationData.formation_finished != true &&
				_vm.formationData.last_exercice_date
			)?_c('AlertChoiceCategory',{attrs:{"listCategories":_vm.listCategories,"listCategoriesDone":_vm.categoriesDone,"formationId":_vm.formationData._id}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }