<template>
	<div class="containerRules">
		<RulesComponent />
	</div>
</template>

<script>
import RulesComponent from '@/components/dashboard/rules/Index';

export default {
	name: 'Rules',
	components: {
		RulesComponent,
	},
};
</script>

<style lang="scss">
.containerRules {
	max-width: 80%;
	margin: auto;
}
@media screen and (max-width: 600px) {
	.containerRules {
		max-width: 90%;
		margin: auto;
	}
}
</style>
