var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"shadow-blue-not-openned mt20 pt-4 pb-4 pl-3 pr-3 pt-md-9 pb-md-9 pl-md-12 pr-md-12 mb-10"},[_c('div',{staticClass:"d-flex rulebadge"},[(_vm.infosRules.score >= 100 && _vm.infosRules.score < 150)?_c('div',[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","small":"","color":"#2dc7ae"}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-checkbox-marked-circle")]),_c('span',{staticClass:"status-rulebadge"},[_vm._v("MAÎTRISÉE")])],1)]}}],null,false,2227531455)},[_c('span',[_vm._v("Tu maîtrises cette règle, ton score supérieur à 100")])])],1):(_vm.infosRules.score >= 150)?_c('div',[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","small":"","color":"#FFD15E"}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-star")]),_c('span',{staticClass:"status-rulebadge"},[_vm._v("MÉMORISÉE")])],1)]}}])},[_c('span',[_vm._v("Tu as mémorisé cette règle, ton score est = 150")])])],1):_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","small":"","color":"black","outlined":""}},'v-chip',attrs,false),on),[_vm._v(" EN COURS DE MAÎTRISE ")])]}}])},[_c('span',[_vm._v("Atteint 100 points pour maitriser cette règle")])])],1)]),_c('div',{staticClass:"mt-2 mb-5 d-flex flex-column flex-md-row justify-space-between align-center"},[_c('h1',{staticClass:"font-weight-bold text-uppercase mb-4 mb-md-0",attrs:{"id":'rule-' + _vm.infosRules.id}},[_vm._v(" "+_vm._s(_vm.infosRules.name)+" ")]),_c('div',{staticClass:"div-points-rule"},[_c('div',{staticClass:"d-flex align-center mb-2 justify-end"},[_c('p',{staticClass:"font-weight-bold mb-0 d-flex justify-end align-center scoreRule",class:{
							greenText: _vm.infosRules.score < 150,
							goldText: _vm.infosRules.score >= 150,
						}},[_c('span',{staticClass:"fs30"},[_vm._v(_vm._s(_vm.infosRules.score))]),(_vm.infosRules.score < 150)?_c('img',{staticClass:"iconsRules",attrs:{"src":require("@/assets/icons/lightening-green.svg")}}):_c('img',{staticClass:"iconsRules",attrs:{"src":require("@/assets/icons/lightening-gold.svg")}}),_c('span',{staticClass:"fs30"},[_vm._v("/"+_vm._s(_vm.infosRules.pointTarget)+" ")])])]),_c('v-progress-linear',{staticClass:"mb20",attrs:{"value":(_vm.infosRules.score / _vm.infosRules.pointTarget) * 100,"height":"10","color":_vm.colorRules(_vm.infosRules.score),"rounded":"","background-color":"#F1F1F1"}})],1)]),_c('h3',{staticClass:"mb-4 text-uppercase text-md-left"},[_vm._v("L'astuce")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.infosRules.description)}}),_c('div',{staticClass:"action-container"},[_c('v-btn',{staticClass:"training-question-btn",attrs:{"depressed":"","dark":"","href":'/rules-training/' + _vm.idFormation + '/' + _vm.infosRules.id},on:{"click":function($event){return _vm.goToTrainingQuestions()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-food")]),_vm._v(" Questions d'entraînement ")],1),(_vm.infosRules.ressourceLink)?_c('div',{staticClass:"fiche-container"},[_c('v-btn',{staticClass:"fiche-icon",attrs:{"href":_vm.infosRules.ressourceLink,"target":"_blank","outlined":"","color":"black"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-eye")]),_vm._v(" Voir la fiche ")],1)],1):_vm._e()],1)]),_c('RulesInfosSessions',{attrs:{"infosRules":_vm.infosRules},on:{"displayRules":_vm.displayRules}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }