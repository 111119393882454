<template>
	<div v-if="this.enterprise">
		<v-dialog persistent v-model="displayCondition" class="dialog-custom" max-width="700">
			<v-card class="modal-formation-end">
				<div class="d-flex justify-end">
					<v-btn text icon class="close-btn mt-2 mr-2" @click="onClose()">
						<v-icon x-large color="black">mdi-alpha-x</v-icon>
					</v-btn>
				</div>
				<div class="modal-formation-end-container">
					<h1 class="text-center modal-title-parrainage">{{ title }}</h1>
					<h2 class="text-center mt-3"><span class="emoji mr-1">👉</span> Débloque 1 mois supplémentaire</h2>
					<p class="text-center mt-2">Parraine quelqu’un de ton entourage et prolonge ta formation gratuitement !</p>
					<v-card-actions>
						<v-spacer></v-spacer>
						<a
							href="https://orthographiq.webflow.io/parrainez-vos-proches-pour-orthographiq?utm_source=app&utm_medium=dashboard-popup-j10&utm_campaign=parrainage"
							@click="goToReferralPage()"
							target="_blank"
						>
							<v-btn color="black" x-large dark class="hvr-grow submit-btn">
								<strong>Parrainer un proche</strong>
							</v-btn>
						</a>
						<v-spacer></v-spacer>
					</v-card-actions>
				</div>
				<br />
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'ModalFormationEndIn10Days',
	props: {
		enterprise: Object,
		formationData: Object,
	},
	data() {
		return {
			forceClose: false,
			storageKey: 'finishpopup10days',
		};
	},
	computed: {
		title() {
			const endDate = moment(this.formationData.end_date);
			const diffEnd = endDate.diff(moment(), 'days');
			if (this.formationData.formation_finished === true || diffEnd < 0) return 'Ta formation est terminée';
			return `J-${diffEnd} avant la fin`;
		},
		displayCondition() {
			const CPF = '5f6c994eac759a00173cef92';
			if (this.forceClose === true) return false;
			if (this.enterprise == null || this.enterprise._id !== CPF) return false;

			const endDate = moment(this.formationData.end_date);
			const now = moment();
			const diffEnd = endDate.diff(now, 'days');
			if (diffEnd > 10) return false;

			const cookie = window.localStorage.getItem(this.storageKey);
			if (cookie == null) return true;

			const { hideUntil } = JSON.parse(cookie);
			if (now.unix() - hideUntil >= 0) return true;

			return false;
		},
	},
	methods: {
		onClose() {
			this.saveLocalStorage(this.storageKey);
			this.forceClose = true;
		},
		saveLocalStorage(key) {
			const newCookie = { hideUntil: moment().add(48, 'hours').unix() };
			window.localStorage.setItem(key, JSON.stringify(newCookie));
		},
		goToReferralPage() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('popup-goToReferralPage');
			}
		},
	},
};
</script>

<style scoped>
.modal-formation-end p {
	font-size: 1.25rem;
	line-height: 1.5;
}

.close-btn {
	width: auto;
	height: auto;
}
.close-btn:hover::before {
	opacity: 0;
}

.emoji {
	font-size: 27px;
}

.submit-btn {
	text-transform: none;
	font-size: 18px;
	width: 220px !important;
}

.modal-formation-end p {
	padding: 0px 50px 0px 50px;
}

@media screen and (min-width: 500px) {
	.submit-btn {
		min-width: 350px !important;
		font-size: 25px;
	}
}

@media screen and (max-width: 500px) {
	.modal-formation-end-container {
		padding: 0px 15px 0px 15px;
	}
	.modal-formation-end h1 {
		line-height: 1;
		font-size: 30px;
	}
	.modal-formation-end h2 {
		padding-top: 15px;
		line-height: 1.2;
		font-size: 20px;
	}
	.modal-formation-end p {
		font-size: 15px;
		padding: 0px 0px 0px 0px;
	}
}
</style>
