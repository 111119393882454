<template>
	<div>
		<v-card class="shadow-blue-not-openned pt-4 pb-4 pl-3 pr-3 pt-md-9 pb-md-9 pl-md-12 pr-md-12">
			<div class="d-flex align-center">
				<v-icon color="black">mdi-binoculars</v-icon>
				<h3 class="font-weight-bold text-uppercase ml-1">Les sessions associées</h3>
			</div>
			<div v-for="(exercice, index) in infosRules.infosExo" :key="index" class="mt-8">
				<span class="subheading ortho-text-gray fs15">
					Session {{ exercice.exercices_id.position_list }} - {{ exercice.exercices_id.cat_id.name }} -
					{{ convertDate(exercice.creation_date) }}
				</span>
				<v-tooltip color="black" top>
					<template v-slot:activator="{ on }">
						<v-expansion-panels class="panelsStories">
							<v-expansion-panel>
								<v-expansion-panel-header v-on="on" expand-icon="" class="pr-0 pl-0 pt-0 pb-0">
									<v-toolbar color="white" class="exerciceContainer shadow-blue-not-openned">
										<v-toolbar-title>{{ exercice.exercices_id.name }}</v-toolbar-title>
										<v-spacer></v-spacer>
										<span v-if="exercice.score >= 0.8" class="subheading">
											<v-chip class="ma-2" color="#2dc7ae" text-color="#2dc7ae" outlined>
												<v-avatar left>
													<v-icon>mdi-checkbox-marked-circle</v-icon>
												</v-avatar>
												<strong>{{ Math.ceil(exercice.score * 100) }}%</strong>
											</v-chip>
										</span>
										<span v-else class="subheading ortho-text-red">
											<v-tooltip color="black" top>
												<template v-slot:activator="{ on }">
													<v-chip class="ma-2" color="orange" text-color="white" v-on="on">
														<strong>
															<v-avatar left>
																<v-icon>mdi-alert-circle</v-icon>
															</v-avatar>
															{{ Math.ceil(exercice.score * 100) }}%
														</strong>
													</v-chip>
												</template>
												<span
													>Il te faut au moins 80% de bonnes réponses<br />
													pour débloquer le prochain exercice</span
												>
											</v-tooltip>
										</span>
										<v-divider class="mx-4" inset vertical></v-divider>
										<v-toolbar-items>
											<v-btn icon color="#2dc7ae" class="hvr-shrink" @click="replayExercice(exercice.exercices_id._id)">
												<v-icon>fas fa-play</v-icon>
											</v-btn>
										</v-toolbar-items>
									</v-toolbar>
								</v-expansion-panel-header>
								<v-expansion-panel-content class="pl-0">
									<div
										v-for="(story, index1) in exercice.story_score"
										:key="index1"
										style="border: 1px solid rgb(248, 248, 248)"
									>
										<div
											class="pl-4 pr-4 pb-2 pt-2 d-flex align-center justify-space-between flex-column flex-md-row"
											@click="displayRule(story.story_id.rule_tags_id.name)"
										>
											<v-chip class="rule_tag_name" label>
												<h4>{{ story.story_id.rule_tags_id.name }}</h4></v-chip
											>
											<div style="position: relative; text-align: right" class="mt-4">
												<p
													:style="{
														color: colorStoryRules(scorePerStory(story.questions_id) / story.questions_id.length),
													}"
													class="font-weight-bold mr-12 mb-2"
												>
													<span>{{ scorePerStory(story.questions_id) * 10 }}</span>
													<img
														class="iconsStory"
														v-if="scorePerStory(story.questions_id) / story.questions_id.length >= 1"
														src="@/assets/icons/lightening-green.svg"
													/>
													<img class="iconsStory" v-else src="@/assets/icons/lightening-green.svg" /><span
														>/{{ story.questions_id.length * 10 }}</span
													>
												</p>
												<v-progress-circular
													rotate="270"
													size="40"
													width="7"
													style="position: absolute; right: 0px; bottom: 0px"
													:value="(scorePerStory(story.questions_id) / story.questions_id.length) * 100"
													:color="colorStoryRules(scorePerStory(story.questions_id) / story.questions_id.length)"
												>
												</v-progress-circular>
											</div>
										</div>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</template>
					<span>Cliquer pour plus de détails</span>
				</v-tooltip>
			</div>
		</v-card>
		<Loading :show="loading_show" :label="loading_label"> </Loading>
	</div>
</template>

<script>
import Loading from 'vue-full-loading';

export default {
	name: 'RulesInfosSessions',
	props: {
		infosRules: Object,
	},
	components: {
		Loading,
	},
	data() {
		return {
			loading_show: false,
			loading_label: '3, 2, 1...',
			loading_timeOut: 1000,
		};
	},
	methods: {
		convertDate(data) {
			let date = new Date(data).toLocaleDateString();
			return date;
		},
		replayExercice(id_exercice) {
			this.loading_show = true;
			const urlTarget = this.$route.params.idFormation + '/' + id_exercice;
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('dashboard-rules-replayExercice');
			}
			setTimeout(() => {
				this.loading_show = false;
				return this.$router.push('/exercices/' + urlTarget);
			}, this.loading_timeOut);
		},
		scorePerStory(questions) {
			let score = 0;
			questions.map((element) => {
				if (element.is_good_answer == true) {
					score++;
				}
			});
			return score;
		},
		colorStoryRules(value) {
			if (value < 1) {
				return '#34e5c8';
			} else {
				return '#34e5c8';
			}
		},
		displayRule(value) {
			this.$emit('displayRules', value);
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.v-expansion-panel-content__wrap {
		padding: 0px;
	}
}
.iconsStory {
	width: 11px;
}
.rule_tag_name {
	cursor: pointer;
}
.rule_tag_name:hover {
	color: black;
	background-color: white;
}
.exerciceContainer:hover {
	background-color: rgb(238, 238, 238) !important;
}
</style>
