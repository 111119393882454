<template>
	<div class="leaderboard-block">
		<div class="leaderboard-title">
			<SonarWave />
			<h2>Classement</h2>
		</div>
		<p class="mt-3">Valide des sessions et gagne des points pour dominer le classement.</p>
		<div>
			<DesktopButton v-if="$vuetify.breakpoint.mdAndUp" />
			<MobileButton v-if="$vuetify.breakpoint.smAndDown" />
		</div>
	</div>
</template>

<script>
import MobileButton from './MobileButton';
import DesktopButton from './DesktopButton';
import SonarWave from '../../../other/Sonar';

export default {
	name: 'LeaderBoardBlock',
	components: { SonarWave, MobileButton, DesktopButton },
};
</script>

<style lang="scss" scoped>
.leaderboard-title {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.leaderboard-block {
	* {
		font-family: 'Lato';
	}
	p {
		font-size: 16px;
	}
}
</style>
