<template>
	<div>
		<div class="to-leaderboard-btn-mobile cursor-pointer" @click="openModal">
			<span class="ortho-bold fs12 ortho-text-gray cta" style="color: rgba(0, 0, 0, 0.54)">MON RANG</span>
			<v-icon class="mr3 arrow-right" color="#979FA9" small>mdi-arrow-right</v-icon>
		</div>
		<!-- Need v-dialog to prevent scrolling -->
		<v-dialog v-model="showModal" persistent></v-dialog>
		<div v-if="showModal" id="modalParcours">
			<v-overlay z-index="300">
				<MobileDialogContent :closeModal="closeModal" />
			</v-overlay>
		</div>
	</div>
</template>

<script>
import { setDisplay } from '@/utils/helper.js';
import MobileDialogContent from '@/components/weekly-leaderboard/MobileDialogContent';
export default {
	name: 'MobileButton',
	components: { MobileDialogContent },
	data() {
		return {
			showModal: false,
		};
	},
	methods: {
		openModal() {
			this.showModal = true;
			if (window.innerWidth < 960) setDisplay('cc-157aw', 'none', 'important'); // Hide chat support

			//mixpanel tracker
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('dashboard-profile-goToLeaderboard');
			}
		},
		closeModal() {
			if (window.innerWidth < 960) setDisplay('cc-157aw', 'block'); // Unhide chat support
			this.showModal = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.to-leaderboard-btn-mobile {
	margin: 0 auto;
	width: 90%;
	max-width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;

	* {
		color: #b4b8b8;
	}

	&:hover {
		.cta {
			color: #686bdf !important;
		}
		.arrow-right {
			color: #686bdf !important;
			transform: translateX(30%);
		}
	}
}
</style>
