import moment from 'moment';

export const checkIfTodayExerciceIsDone = (formation) => {
	const { last_exercice_date, finish_position_test_date } = formation;

	if (last_exercice_date === finish_position_test_date) {
		return false; //we let test trial to do the first exercice
	} else if (moment().isSame(last_exercice_date, 'day')) {
		return true; //we restrict full user to do the first exercice
	} else {
		return false; //exercice of the day is not done yet
	}
};
