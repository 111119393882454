<template>
	<div class="sonar-wrapper">
		<div class="sonar-emitter">
			<div class="sonar-wave sonar-wave1"></div>
			<div class="sonar-wave sonar-wave2"></div>
			<div class="sonar-wave sonar-wave3"></div>
			<div class="sonar-wave sonar-wave4"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SonarWave',
};
</script>

<style>
.sonar-wrapper {
	position: relative;
	z-index: 0;
	overflow: hidden;
}

.sonar-emitter {
	position: relative;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 8px;
	height: 8px;
	border-radius: 9999px;
	background-color: black;
}

.sonar-wave {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 9999px;
	background-color: black;
	opacity: 0;
	z-index: -1;
	pointer-events: none;
}

.sonar-wave1 {
	animation: sonarWave 1s linear infinite;
}

.sonar-wave2 {
	animation: sonarWave 1s 0.5s linear infinite;
}

.sonar-wave3 {
	animation: sonarWave 1s 1s linear infinite;
}

.sonar-wave4 {
	animation: sonarWave 1s 1.5s linear infinite;
}

@keyframes sonarWave {
	from {
		opacity: 0.4;
	}
	to {
		transform: scale(3);
		opacity: 0;
	}
}
</style>
